'use client';
import { errorSlackAlert } from '@/utils/errorSlackAlert';
import classes from './error.module.css';

const SomethingWentWrongModal = ({ error }) => {
	errorSlackAlert(error);

	return (
		<div className={classes.bg}>
			<section className={classes.main}>
				<div className={classes.section}>
					<img
						src="https://ik.imagekit.io/kuj9zqlrx0or/Static/wentWrong.png?tr=w-500"
						alt="404"
						className={classes.image}
					/>
					<div className={classes.textSection}>
						<h1 className={classes.h1}>Something Went Wrong</h1>
						<div className={classes.p}>
							We are fixing the problem. In the meantime, please reload.
						</div>
						<button
							className={classes.reload}
							onClick={() => global?.window && window.location.reload()}
						>
							Reload
						</button>
						<div className={classes.p}>
							<br /> or{' '}
							<a href="/" style={{ color: 'gray' }}>
								Go back to the homepage
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default SomethingWentWrongModal;
