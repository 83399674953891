import axios from 'axios';

export const errorSlackAlert = async (error) => {
	if (!error) return;
	let { message, stack, name, digest } = error;

	if (
		process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
		process.env.NEXT_PUBLIC_OG_DB == 'true'
	) {
		// if (true) {
		await axios
			.post(
				`${process.env.NEXT_PUBLIC_SERVER_API}/api/other/slack-client-bug-next`,
				{
					error: {
						message,
						stack,
						name,
						digest,
					},
				}
			)

			.catch((err) => {
				console.log(err);
			});
	}
};
